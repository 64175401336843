<template>
  <v-list
    dense
    width="263px"
    class="py-0 add-to-container-list"
    :class="{ 'dark-theme': darkTheme }"
    :dark="darkTheme"
    @click.stop
  >
    <v-text-field
      v-model.lazy="search"
      solo
      flat
      hide-details
      background-color="transparent"
      class="mx-1 add-to-container-search-bar"
      :disabled="initializing"
      @click.stop
    >
      <template v-slot:label>
        <div class="testing-this-tout">
          Add
          <template v-if="companies.length === 1">
            <v-avatar
              tile
              color="#fff"
              size="16px"
              style="
                margin: 0 2px;
                border-radius: 2px !important;
                bottom: 2px;
                padding: 2px;
              "
            >
              <v-img v-if="companyLogo" :src="companyLogo" contain />
              <v-icon v-else size="14px" color="red">business</v-icon>
            </v-avatar>
            {{ companyName }} to...
          </template>
          <template v-else> {{ companies.length }} Companies </template>
        </div>
      </template>
      <v-icon
        slot="append"
        size="20px"
        class="mb-2"
        style="opacity: 0.7"
        @click.stop
        >search</v-icon
      >
    </v-text-field>

    <v-divider @click.stop />

    <template v-if="initializing">
      <BaseLoader
        class="py-16 ma-0"
        :size="93"
        :loading="true"
        :color="darkTheme ? 'white' : null"
      />
    </template>
    <template v-else>
      <v-progress-linear
        v-if="loading"
        :color="darkTheme ? 'white' : null"
        height="2"
        indeterminate
        style="position: absolute; width: 263px"
      ></v-progress-linear>

      <v-subheader class="px-4" style="margin-bottom: -2px" @click.stop>
        {{ _.isEmpty(search) ? "Most Recent Reports" : "Search Results" }}
      </v-subheader>

      <v-subheader
        v-show="!loading && _.isEmpty(searchResults)"
        class="px-4 mt-4 mb-3"
        @click.stop
      >
        No results found...
      </v-subheader>

      <v-list-item
        v-for="(container, index) in searchResults"
        :key="`container-${index}`"
        :disabled="loading"
        class="add-to-container-list-items"
        @click.stop="addToContainer(container)"
      >
        <v-list-item-title>{{ container.name }}</v-list-item-title>
      </v-list-item>

      <v-divider class="mt-1" />

      <v-list-item
        @click.stop="createContainer"
        :disabled="loading"
        class="create-new-container-action"
      >
        <v-list-item-title>
          <v-icon size="20" left>add_box</v-icon>
          <span>New Container</span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex"
import { form } from "@mixins/reports/form"
import SearchMixin from "@mixins/search"
import axios from "axios"

export default {
  name: "AddToContainerList",
  mixins: [form],
  props: {
    companies: {
      type: Array,
      default() {
        return []
      },
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuActive: false,
      search: null,
      searchResults: [],
      searchTimeout: null,
      selectedModels: null,
      loading: false,
      selectedContainer: null,
      meta: null,
      initializing: true,
    }
  },
  computed: {
    ...mapGetters({
      activeUser: "auth/activeUser",
      activeGroup: "auth/activeGroup",
      shareToken: "auth/shareToken",
    }),
    searchContext() {
      return Object.freeze({
        page: 1,
        per_page: 5,
        indices: ["reports"],
        filters: this.filtersObj,
        sorts: [{ attr: "created_at", order: "desc" }],
        includes: ["name", "logo_url"],
      })
    },
    filtersObj() {
      const filtersObj = [
        {
          attr: "group_id",
          type: "term",
          value: this.activeGroup.id,
        },
        {
          attr: "access_policy",
          type: "terms",
          value: SearchMixin.buildAccessPolicyWords({
            group: [{ id: this.activeGroup.id, action: "write" }],
          }),
        },
      ]

      if (this.search) {
        filtersObj.push({
          attr: "name",
          type: "text",
          verb: "filter",
          nestedVerb: "should",
          value: this.search,
        })
      }

      return filtersObj
    },
    firstCompany() {
      return this.companies[0]
    },
    companyName() {
      return (
        _.get(this.firstCompany, "name") || _.get(this.firstCompany, "kt_name")
      )
    },
    companyLogo() {
      return (
        _.get(this.firstCompany, "logo_url") ||
        _.get(this.firstCompany, "kt_logo_url")
      )
    },
    snackbarText() {
      return this.companies.length > 1
        ? `Successfully added ${this.companies.length} companies to ${this.selectedContainer.name}`
        : `${this.companyName} successfully added to ${this.selectedContainer.name}`
    },
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout)
  },
  watch: {
    search() {
      if (this.search && this.search.length < 2) {
        return
      }

      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        this.performSearch()
      }, 500)
    },
  },
  created() {
    this.meta = {
      plural: true,
      model: this.companies,
      modelType: "company",
      indexToSearch: "reports",
      bulkAddToModel: this.companies,
      actionSet: {
        set: "crud",
        action: "create",
      },
    }

    this.performSearch()
  },
  methods: {
    addToContainer(container) {
      if (!this.searchResults) {
        return
      }
      this.loading = true

      this.selectedContainer = container

      return axios
        .put("snowflakes/report_actions/add", {
          company_id_array: this.companies.map((x) => x.id),
          report_ids: [container.id],
        })
        .then(() => {
          this.$emit("closeParentMenu")
          this.displaySnackbar()
        })
        .catch((e) => {
          console.error(e)
          const snackbarArgs = {
            text: "An error occurred while trying to add to report.",
            icon: "error",
            iconColor: "red",
            timeout: 6000,
          }

          this.$store.dispatch("app/setSnackbar", snackbarArgs)
        })
        .finally(() => {
          this.loading = false
        })
    },
    createContainer() {
      this.loading = true

      this.$store
        .dispatch("reports/createReport", {
          name: "New Report",
          group_id: this.activeGroup.id,
          user_id: this.activeUser.id,
        })
        .then((newReport) => {
          return this.addModelsToNewReport(newReport)
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.menuActive = false
        })
    },
    displaySnackbar() {
      const snackbarArgs = {
        classNames: ["company-added-to-container"],
        container: this.selectedContainer,
        companies: this.companies,
        text: this.snackbarText,
        timeout: 6000,
      }

      this.menuActive = false
      this.$store.dispatch("app/setSnackbar", snackbarArgs)
    },
    performSearch() {
      this.loading = true

      axios
        .get("search", {
          params: { q: SearchMixin.buildSearchObject(this.searchContext) },
        })
        .then((response) => {
          this.searchResults = _.uniq(response.data.results, [])
        })
        .catch((e) => {
          console.error("Error searching share modal", e)
        })
        .finally(() => {
          this.loading = false
          this.initializing = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
div.add-to-container-list {
  border-radius: 4px;
  &.dark-theme {
    background: linear-gradient(180deg, #1c1836 0%, #453e60 100%);
  }

  div.add-to-container-search-bar {
    margin: 0 16px 0 8px !important;

    &.v-input--is-focused {
      margin: 0 16px !important;
    }
  }

  div.v-subheader {
    height: 30px;
  }

  div.add-to-container-list-items {
    min-height: 30px;
  }

  div.create-new-container-action {
    min-height: 38px;
    padding: 0 8px;

    span {
      position: relative;
      top: 2px;
    }
  }
}
</style>

<style lang="scss">
div.add-to-container-list {
  div.add-to-container-search-bar {
    height: 40px !important;
    div.v-input__slot {
      padding: 0px !important;
      div.v-text-field__slot {
        input {
          margin-bottom: 8px;
        }
        label {
          top: unset !important;
          bottom: 12px;
          background: rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          height: 24px;
          display: flex;
          align-items: center;

          div.testing-this-tout {
            position: relative;
            top: 1px;
            padding: 0 8px;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }
  }

  &.dark-theme {
    div.add-to-container-search-bar
      div.v-input__slot
      div.v-text-field__slot
      label {
      background: rgba(255, 255, 255, 0.16);

      div.testing-this-tout {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
